import { createContext, useContext, useEffect, useState } from 'react';
import useAlert from '../hooks/Alert';
import Cookies from "js-cookie";

const userThemeCookieName = 'wafaa-user-data-theme';

const AppSettingContext = createContext();

export function useAppSetting() {
  return useContext(AppSettingContext);
}

const getSubdomain = (url) => {
  console.log("getting subdomain :",url);
  let domain = url;
  if (url.includes("://")) {
    domain = url.split('://')[1];
  }
  const subdomain = domain.split('.')[0];
  return subdomain ?? 'wafaa';
};

export function AppSettingProvider(props) {
  const [appSettings, setAppSettings] = useState(undefined);

  const [clientAccess, setClientAccess] = useState(undefined);

  const [ theme, setTheme ] = useState('light');

  const { setShowAlert, setAlertStyle, setAlertMessage, hideAlert } = useAlert();

  useEffect(() => {
    fetchBaseURL();
  }, []);

  const fetchBaseURL = async () => {
    var subdomainID = getSubdomain((new URL(window.location.href)).origin);
    console.log("subdomainID",subdomainID);
    if(subdomainID === undefined){
      subdomainID = "wafaa";
    }
  if (subdomainID !== "rnm") {
      subdomainID = "wafaa";
  }

    console.log("subdomainID after checking",subdomainID);
    const payLoad = {
      "FUNCTION": "FileReader",
      "APP_ID": "TrueValue",
      "VERSION": "V1.0",
      "SUB_DOMAIN_ID": subdomainID,
      "FileName": `LoyaltyApplication/settings/${subdomainID}/appconfig.json`
    }
    const request = await fetch("https://web2.mycomsys.com:8803/api/appconfig/v1/GetAppSettings", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payLoad)
    });

    const response = await request.json();
    console.log("response for appsettings",response);
    if (response.AppBaseURL) {
      setAppSettings(response);
      await fetchClientAccess(response);
    } else {
      if (subdomainID === 'localhost:3000' || subdomainID.includes('192')) {
        fetchBaseURLRetryDefault();
      } else {
        hideAlert();
        setAlertStyle({
          background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
        })
        setAlertMessage({
          success: false,
          message: "Failed to get app settings",
          mode: 'App Setting'
        });
        setShowAlert('d-block');
      }
    }

    setTheme(Cookies.get(userThemeCookieName) ?? 'light');
  }

  const fetchClientAccess = async (settingsResponse) => {
    const payLoad = {
      "DATA": {
        "CLIENT_ID": settingsResponse.ClientId
      }
    };

    const request = await fetch(settingsResponse.GetAccess, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payLoad)
    });

    const response = await request.json();

    setClientAccess(response.DATA);
  }

  const fetchBaseURLRetryDefault = async () => {
    const subdomainID = getSubdomain((new URL('https://wafaa.elvisben.me.ke')).origin);
    const payLoad = {
      "FUNCTION": "FileReader",
      "APP_ID": "TrueValue",
      "VERSION": "V1.0",
      "SUB_DOMAIN_ID": subdomainID,
      "FileName": `LoyaltyApplication/settings/${subdomainID}/appconfig.json`
    }
    const request = await fetch("https://web2.mycomsys.com:8803/api/appconfig/v1/GetAppSettings", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payLoad)
    });

    const response = await request.json();
    if (response.AppBaseURL) {
      setAppSettings(response);
      await fetchClientAccess(response);
    }
  }

  return (
    <AppSettingContext.Provider value={{appSettings, clientAccess, theme, setTheme}}>
      {props.children}
    </AppSettingContext.Provider>
  )
}